import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    title: 'Rate Calculator',
    description:
      'Rate Calculator is an app that can show current best rates from banks within area of zipcode.',
    imageUrl: '/images/products/product_6.png',
    totalDownloads: '594',
    updatedAt: '27/03/2019'
  },
  {
    id: uuid(),
    title: 'Dream House',
    description:
      'Dream House is an app that can show best matching houses in the area matching your criteria.',
    imageUrl: '/images/products/product_6.png',
    totalDownloads: '625',
    createdAt: '31/03/2019'
  },
  {
    id: uuid(),
    title: 'Financial Advisor',
    description:
      'Financial Advisor is an tool that helps you plan your finances appropriately based on your Income and spending history.',
    imageUrl: '/images/products/product_6.png',
    totalDownloads: '857',
    createdAt: '03/04/2019'
  },
  {
    id: uuid(),
    title: 'SmartTrader',
    description:
      'Smart Trader app helps you decide which stocks are potential buys based on their news and market situation',
    imageUrl: '/images/products/product_6.png',
    totalDownloads: '406',
    createdAt: '04/04/2019'
  },
  {
    id: uuid(),
    title: 'InvestLand',
    description:
      'InvestLand is a tool that shows list of profit buy options at various locations on map depending on budget.',
    imageUrl: '/images/products/product_6.png',
    totalDownloads: '835',
    createdAt: '04/04/2019'
  },
  {
    id: uuid(),
    title: 'RealPros',
    description:
      'RealPros are our team of highly successful individuals that share their experiences and suggestions on investing, financial planning, retirement planning, etc.. .',
    imageUrl: '/images/products/product_6.png',
    totalDownloads: '835',
    createdAt: '04/04/2019'
  }
];
